export type ListPlaceholderSimpleProps = {
    text: string;
};

function ListPlaceholderSimple(props: ListPlaceholderSimpleProps): JSX.Element {
    const { text } = props;

    return <span className="w-full p-24 text-body-bold-16 opacity-50 whitespace-pre-wrap text-center">{text}</span>;
}

export default ListPlaceholderSimple;
