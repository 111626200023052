export type DotSeparatedLabelProps = {
    className?: string;
    classNameLabel?: string;
    classNamePrimaryLabel?: string;
    classNameDot?: string;
    labels: (string | JSX.Element)[];
};

function DotSeparatedLabel(props: DotSeparatedLabelProps): JSX.Element {
    const { classNameLabel, classNamePrimaryLabel, labels: labelProps, classNameDot, className } = props;

    const filteredLabels = labelProps.filter((label) => {
        if (label !== undefined || label !== '' || label !== null) {
            return label;
        }
    });

    const dotSeparatedLabel: JSX.Element[] = filteredLabels.map((label, index) => (
        <span
            className={index === 0 && classNamePrimaryLabel ? classNamePrimaryLabel : classNameLabel}
            key={`label#${index}`}>
            {label}
            {index < filteredLabels.length - 1 && <span className={`${classNameDot} mx-4`}>·</span>}
        </span>
    ));

    return <div className={className}>{dotSeparatedLabel}</div>;
}

export default DotSeparatedLabel;
